/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
.mdc-tab-indicator {
  display: -webkit-box;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1; }
  .mdc-tab-indicator > .mdc-tab-indicator__content--underline {
    background-color: #6200ee;
    /* @alternate */
    background-color: var(--mdc-theme-primary, #6200ee); }
  .mdc-tab-indicator > .mdc-tab-indicator__content--underline {
    height: 2px; }
  .mdc-tab-indicator > .mdc-tab-indicator__content--icon {
    color: #018786;
    /* @alternate */
    color: var(--mdc-theme-secondary, #018786); }
  .mdc-tab-indicator > .mdc-tab-indicator__content--icon {
    height: 34px;
    font-size: 34px; }

.mdc-tab-indicator__content {
  -webkit-transform-origin: left;
          transform-origin: left;
  opacity: 0; }

.mdc-tab-indicator__content--underline {
  align-self: flex-end;
  width: 100%; }

.mdc-tab-indicator__content--icon {
  align-self: center;
  margin: 0 auto; }

.mdc-tab-indicator--active > .mdc-tab-indicator__content {
  opacity: 1; }

.mdc-tab-indicator > .mdc-tab-indicator__content {
  -webkit-transition: 250ms -webkit-transform cubic-bezier(0.4, 0, 0.2, 1);
  transition: 250ms -webkit-transform cubic-bezier(0.4, 0, 0.2, 1);
  transition: 250ms transform cubic-bezier(0.4, 0, 0.2, 1);
  transition: 250ms transform cubic-bezier(0.4, 0, 0.2, 1), 250ms -webkit-transform cubic-bezier(0.4, 0, 0.2, 1); }

.mdc-tab-indicator--no-transition > .mdc-tab-indicator__content {
  -webkit-transition: none;
  transition: none; }

.mdc-tab-indicator--fade > .mdc-tab-indicator__content {
  -webkit-transition: 150ms opacity linear;
  transition: 150ms opacity linear; }

.mdc-tab-indicator--active.mdc-tab-indicator--fade > .mdc-tab-indicator__content {
  -webkit-transition-delay: 100ms;
          transition-delay: 100ms; }


/*# sourceMappingURL=tab-indicator.css.map*/